import React from "react";
import { StaticQuery, graphql } from "gatsby";
// import { getMdxProps } from "gatsby-theme-hypercore/src/templates/page";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Box, Button, Typography, withStyles } from "@material-ui/core";
// import LaunchIcon from '@mui/icons-material/Launch';
import Layout from "gatsby-theme-hypersite/src/layout";
import { formatDate } from "../utils";
import { Content, PageHero } from "../components";

const ReportPage = ({ classes, data, ...props }) => {
	const report = { ...data.mdx, ...data.mdx.frontmatter };

	// const data = useStaticQuery(graphql`
	//    query HeaderQuery {
	//      mdx(id: { eq: ${id} }) {
	// 			id
	// 			body
	// 			slug
	// 			frontmatter {
	// 				hero
	// 				title			s
	// 				meta {
	// 					title
	// 					description
	// 					keywords
	// 				}
	// 			}
	// 		}
	//    }
	//  `);

	return (
		<Layout {...props}>
			<Content className={classes.content}>
				<Box className={classes.meta}>
					{report.date ? (
						<Box>
							<Typography variant="h6">Published</Typography>
							<Typography paragraph>{formatDate(report.date)}</Typography>
						</Box>
					) : null}

					{report.authors ? (
						<Box>
							<Typography variant="h6">Authors</Typography>
							<Typography paragraph>{report.authors.join(", ")}</Typography>
						</Box>
					) : null}

					{report.url ? (
						<Box className={classes.buttonWrapper}>
							<Button
								role="presentation"
								variant="contained"
								target="_blank"
								href={report.url}
								className={classes.button}
							>
								Open report
							</Button>
						</Box>
					) : null}
				</Box>

				<MDXRenderer {...report} {...props}>
					{report.body}
				</MDXRenderer>
			</Content>
		</Layout>
	);
};

export default withStyles(theme => ({
	root: {
		paddingTop: 0
	},
	content: {
		"& > *:first-child": {
			marginTop: 0
		}
	},
	meta: {
		// fontSize: theme.typography.pxToRem(12),
		marginBottom: theme.spacing(4),
		paddingBottom: theme.spacing(2),
		borderBottom: `2px solid ${theme.palette.blue.med}`
	},
	buttonWrapper: {
		padding: theme.spacing(4, 0),
		"& .MuiButton-root": {}
	},
	button: {
		"& .MuiButton-label::after": {
			content: `""`,
			width: theme.spacing(2),
			height: theme.spacing(2),
			display: "inline-block",
			marginTop: 0,
			marginLeft: theme.spacing(1),
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			backgroundImage: "url(/images/icon-launch-light.svg)",
			borderWidth: 0,
			transform: "none"
		}
	}
}))(ReportPage);

// report: researchReports(id: { eq: $id }) {

// }
export const reportQuery = graphql`
	query ($id: String) {
		mdx(id: { eq: $id }) {
			id
			slug
			body
			frontmatter {
				authors
				blurb
				date
				name
				title
				url
			}
		}
	}
`;
